import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function Details() {
  const { id } = useParams();
  const imgDiv = useRef();

  const baseUrl = `${process.env.REACT_APP_API_URL}store-api/get-product/${id}`;

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setProductData([response.data]);
    });
  }, []);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    imgDiv.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  return (
    <section className="bg-white">
      <div className="container bg-grey">
        {productData &&
          productData.map((product) => (
            <div className="details border " key={product.id}>
              <div
                className="details-img"
                onMouseMove={handleMouseMove}
                style={{ backgroundImage: `url(${product.images})` }}
                ref={imgDiv}
                onMouseLeave={() =>
                  (imgDiv.current.style.backgroundPosition = `center`)
                }
              />

              <div className="details-content ">
                <img
                  className="thumb"
                  src={product.thumbnailImage.fileName}
                  alt={product.thumbnailImage.fileName}
                />
                <a
                  style={{ color: "#d18282", fontSize: "20px", margin: "12px" }}
                >
                  &#8377; {product.price}
                </a>
                <span style={{ float: "right" }}>
                  <img
                    className=""
                    src="star-87.png"
                    style={{ marginRight: "10px" }}
                  />
                  <span className="ml-3">4.5</span>
                </span>
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "normal",
                    margin: "12px",
                  }}
                  title={product.name}
                >
                  {product.name}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "initial",
                    margin: "12px",
                  }}
                  className="text-secondary"
                >
                  {product.shortDescription}
                </p>
                <p>{product.description}</p>
                <button
                  className="btn btn-lg text-white"
                  style={{ float: "right", backgroundColor: "#e97f72" }}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

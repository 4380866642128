import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Products from "./Product";
import Details from "./components/Details/Details";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/joybiome" element={<Products appName="joybiome" />} />
        <Route path="/joyscore" element={<Products appName="joyscore" />} />
        <Route path="/joyurth" element={<Products appName="joyurth" />} />
        <Route path="/products/:id" element={<Details />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

export default function Product({ appName }) {
  const [searchBar, setSearchBar] = useState(false);
  const [filterBar, setFilterBar] = useState(false);

  const [search, setSearch] = useState("");

  function handleChange(e) {
    setSearch(e.target.value);
    setFilterBar(false);
  }

  const handleFilter = () => {
    setSearchBar(false);
  };

  const handleSearch = () => {
    setSearchBar(true);
    setFilterBar(false);
  };

  const baseUrl = `${process.env.REACT_APP_API_URL}store-api/get-products?filter[where][appId]=${appName}`;

  const [productData, setProductData] = useState();

  useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setProductData(response.data.data);
    });
  }, []);

  return (
    <>
      <div className="container">
        <Row>
          <Col lg="12" sm="12" xs="12">
            <div className="input-group p-2 d-flex flex-row-reverse">
              {searchBar === false && (
                <>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={handleFilter}
                  >
                    <img src="Icon.png"  alt="search"/>
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    style={{ float: "right" }}
                    onClick={handleSearch}
                  >
                    {" "}
                    <img src="Shape.png" alt="filter" />
                  </button>
                </>
              )}

              {searchBar === true && (
                <>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={handleFilter}
                  >
                    <img src="Icon.png" alt="search"/>
                  </button>
                  <div className="form-outline ">
                    <input
                      type="search"
                      id="form1"
                      className="form-control mt-2"
                      onChange={handleChange}
                    />
                  </div>
                  {filterBar === true && (
                    <div>
                      <Card
                        style={{
                          width: "18rem",
                        }}
                      >
                        <ListGroup flush>
                          <ListGroupItem>
                            <input
                              type="checkbox"
                              className="m-3"
                              // onChange={handleFilterChange}
                            />
                            Food
                          </ListGroupItem>
                        </ListGroup>
                      </Card>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>

        <div>


        {productData && productData.length === 0 && (
            <Row className="p-4 mt-4 border rounded border-light bg-warning font-weight-bold">              
                <span class=" text-white">No More Products</span>
            </Row>
        )}

          <Row>
            {search.length === 0 &&
              productData &&
              productData.map((product) => (
                <Col
                  sm="3"
                  xs="6"
                  className="d-flex align-self-stretch"
                  key={product.id}
                >
                  <Card className="product-card ">
                    <Link to={`/products/${product.id}`}>
                      <img
                        className="card-img"
                        alt={product.thumbnailImage.fileName}
                        src={product.thumbnailImage.fileName}
                      />
                    </Link>
                    <CardBody>
                      <CardTitle tag="h5">
                        <Link
                          className="mt-2 fs-5 mr-2"
                          to={`/products/${product.id}`}
                        >
                          {product.name}
                        </Link>
                        <img
                          src="heart-80.png"
                          alt="like"
                          style={{ width: "15px", float: "right" }}
                          className="mt-2"
                        />
                      </CardTitle>
                      <CardText style={{ color: "gray", fontSize: "15px" }}>
                        {product.shortDescription}
                      </CardText>

                      <CardText>
                        <a style={{ color: "#b02b2c", fontSize: "15px" }}>
                          {" "}
                          &#8377; {product.price}
                        </a>

                        <span style={{ float: "right" }}>
                          <img
                            className=""
                            src="star-87.png"
                            style={{ marginRight: "10px" }}
                          />
                          <span className="ml-3">4.5</span>
                        </span>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
          

          <Container>
            <Row>
              {search.length > 0 &&
                productData &&
                productData
                  .filter((item) => item.name.toLowerCase().includes(search))
                  .map((country) => (
                    <Col sm="3" xs="6" key={country.id}>
                      <Card
                        className="d-flex align-self-stretch product-card "
                        key={country.id}
                      >
                        <Link to={`/products/${country.id}`}>
                          <img
                            className="card-img"
                            alt={country.thumbnailImage.fileName}
                            src={country.thumbnailImage.fileName}
                          />
                        </Link>
                        <CardBody>
                          <CardTitle tag="h5">
                            <Link
                              className="mt-2 fs-5 mr-2"
                              to={`/products/${country.id}`}
                            >
                              {country.name}
                            </Link>
                            <img
                              src="heart-80.png"
                              style={{ width: "15px", float: "right" }}
                              className="mt-2"
                            />
                          </CardTitle>
                          <CardText style={{ color: "gray", fontSize: "15px" }}>
                            {country.shortDescription}
                          </CardText>

                          <CardText>
                            <a style={{ color: "#d18282", fontSize: "15px" }}>
                              {" "}
                              &#8377; {country.price}
                            </a>

                            <span style={{ float: "right" }}>
                              <img
                                className=""
                                src="star-87.png"
                                style={{ marginRight: "10px" }}
                              />
                              <span className="ml-3">4.5</span>
                            </span>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
